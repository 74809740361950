* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    max-width: 100vw;
}

/* ::-webkit-scrollbar {
    appearance: none;
    width: 6px;
    height: 6px;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: var(--red);
  }
  
  ::-webkit-scrollbar-track {
    background-color: #ffffff;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  } */

img {
    display: block;
}

/*********************\ DISPLAY /*********************/

:focus {
    outline: transparent auto 0px;
}

.relative {
    position: relative !important;
}

.flex {
    display: flex;
}

.flexColumn {
    flex-flow: column;
}

.ocultarDisplay {
    display: none !important;
    transition: 1s;
}

.justifyCenter {
    justify-content: center !important;
}

.alignCenter {
    align-items: center;
}

.alignEnd {
    align-items: flex-end;
}

.justifyEnd {
    justify-content: flex-end;
}

.justifyBetween {
    justify-content: space-between;
}

.justifyAround {
    justify-content: space-around;
}

.noShadow {
    box-shadow: initial !important;
}

/*********************\ MARGIN /*********************/

.nomargin {
    margin: 0 !important;
}

.sectionStart {
    margin-top: 3rem;
}

.margin_05_top {
    margin-top: 0.5rem !important;
}

.margin_1_top {
    margin-top: 1rem !important;
}

.margin_2_top {
    margin-top: 2rem !important;
}

.margin_3_top {
    margin-top: 3rem !important;
}

.margin_1 {
    margin: 1rem !important;
}

.margin_2 {
    margin: 2rem !important;
}

.margin_2_vert {
    margin: 2rem 0 !important;
}

.iconLeft {
    margin-right: 7px;
}

.iconRight {
    margin-left: 7px;
}

.margin_1_left {
    margin-left: 1rem !important;
}

.margin_1_right {
    margin-right: 1rem !important;
}

.margin_2_left {
    margin-left: 2rem !important;
}

.margin_2_right {
    margin-right: 2rem !important;
}

.margin_3_left {
    margin-left: 3rem !important;
}

.margin_3_right {
    margin-right: 3rem !important;
}

/*********************\ PADDING /*********************/

.nopadding {
    padding: 0px !important;
}

.padding_horiz_none {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.padding_025 {
    padding: 0.25rem !important;
}

.padding_05 {
    padding: 0.5rem !important;
}

.padding_1 {
    padding: 1rem !important;
}

.padding_1_05 {
    padding: 0.5rem 1rem !important;
}

.padding_2 {
    padding: 2rem !important;
}

.padding_05_top {
    padding-top: 0.5rem !important;
}

.padding_1_top {
    padding-top: 1rem !important;
}

.padding_2_top {
    padding-top: 2rem !important;
}

.padding_3_top {
    padding-top: 3rem !important;
}

.padding_05_left {
    padding-left: 0.5rem !important;
}

.padding_1_left {
    padding-left: 1rem !important;
}

.padding_12_left {
    padding-left: 1.2rem !important;
}

.padding_125_left {
    padding-left: 1.25rem !important;
}

.padding_2_left {
    padding-left: 2rem !important;
}

.padding_3_left {
    padding-left: 3rem !important;
}

.padding_05_right {
    padding-right: 0.5rem !important;
}

.padding_1_right {
    padding-right: 1rem !important;
}

.padding_12_right {
    padding-right: 1.2rem !important;
}

.padding_125_right {
    padding-right: 1.25rem !important;
}

.padding_2_right {
    padding-right: 2rem !important;
}

.padding_3_left {
    padding-left: 3rem !important;
}

.padding_1_bottom {
    padding-bottom: 1rem !important;
}
/*********************\ TEXT /*********************/

.textJustify {
    text-align: justify;
}

.textRight {
    text-align: right;
}

.textUpper {
    text-transform: uppercase;
}

.textCapitalize {
    text-transform: capitalize;
}

.textItalic {
    font-style: italic;
}

.textLower {
    text-transform: lowercase !important;
}

.textUnderline {
    text-decoration: underline !important;
}

.semiBold {
    font-weight: 600;
}

.medium {
    font-weight: 500;
}

.light {
    font-weight: 300;
}

.bold {
    font-weight: 700;
}

.black {
    font-weight: 900;
}

/*********************\ RESIZES /*********************/

.full_absolute {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.desktop {
    display: block;
}

.mobile {
    display: none;
}

.desktopflex {
    display: flex;
}

.widthfull {
    width: 100% !important;
}

.heightfull {
    height: 100%;
}

.containerEighteen {
    width: 80%;
    margin-left: 10%;
    max-width: 1280px;
}

.showMobile {
    display: none;
}

.hideMobile {
    display: block;
}

@media (max-width: 961px) {
    .showMobile {
        display: none;
    }
    .hideMobile {
        display: block;
    }
    .desktop {
        display: none;
    }
    .desktopflex {
        display: none;
    }
    .mobile {
        display: block;
    }
    .clearContainer {
        width: 100% !important;
        margin-left: 0% !important;
    }
    /* CONTAINER MOBILE */
    .containerMobile {
        width: 90%;
        margin-left: 5%;
    }
    /* ALINHAMENTO */
    .centerMobile {
        text-align: center;
    }
    /* MARGIN */
    .margin_1_bottom_mobile {
        margin-bottom: 1rem !important;
    }
    .margin_1_top_mobile {
        margin-top: 1rem !important;
    }
    .margin_2_top_mobile {
        margin-top: 2rem !important;
    }
    .margin_top_none_mobile {
        margin: 0px !important;
    }
    .nopaddingmobile {
        padding: 0px !important;
    }
    .padding15mobile {
        padding: 15px !important;
    }
    .margin_1_0_mobile {
        margin: 1rem 0 !important;
    }
    .margin_center_mobile {
        margin: 0 auto !important;
    }
}

/* TABLET */

@media (max-width: 768px) {
    .showMobile {
        display: none;
    }
    .hideMobile {
        display: block;
    }
    html {
        overflow-x: hidden;
    }

    h4 {
        font-size: 1.8rem !important;
    }

    .containerEighteen {
        width: 95% !important;
        margin: 0 auto !important;
    }

    .flexColumnMobile {
        flex-direction: column;
    }

    .flexColumnReverseMobile {
        flex-direction: column-reverse;
    }

    .alignItemsMobile {
        align-items: flex-start !important;
    }
}
/* MOBILE ONLY */

@media (max-width: 540px) {
    .flex {
        display: flex;
    }
    .showMobile {
        display: block;
    }
    .hideMobile {
        display: none;
    }
}
